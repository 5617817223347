:root {
  --primary-bg-color: #F10382;
  --secondary-bg-color: #fcedf7;
}

@font-face {
font-family: 'Sifonn Pro';
font-style: normal;
font-weight: normal;
src: local('Sifonn Pro'), url('./assets/fonts/SIFONN_PRO.woff') format('woff');
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SF UI Text Regular';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Regular'), url('./assets/fonts/SFUIText-Regular.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Italic'), url('./assets/fonts/SFUIText-RegularItalic.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Light';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Light'), url('./assets/fonts/SFUIText-Light.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Light Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Light Italic'), url('./assets/fonts/SFUIText-LightItalic.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Medium';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Medium'), url('./assets/fonts/SFUIText-Medium.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Medium Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Medium Italic'), url('./assets/fonts/SFUIText-MediumItalic.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Semibold';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Semibold'), url('./assets/fonts/SFUIText-Semibold.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Semibold Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Semibold Italic'), url('./assets/fonts/SFUIText-SemiboldItalic.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Bold';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Bold'), url('./assets/fonts/SFUIText-Bold.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Bold Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Bold Italic'), url('./assets/fonts/SFUIText-BoldItalic.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Heavy';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Heavy'), url('./assets/fonts/SFUIText-Heavy.woff') format('woff');
}


@font-face {
font-family: 'SF UI Text Heavy Italic';
font-style: normal;
font-weight: normal;
src: local('SF UI Text Heavy Italic'), url('./assets/fonts/SFUIText-HeavyItalic.woff') format('woff');
}


.background-clouds {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  z-index: -999;
}

.autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}


.error-message {
  color: red;
}


@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

.footer-img {padding: 15px; border-top: 1px solid #ccd;}
.footer-img img {width: 80%; max-width: 150px;}
.text-center{text-align:center!important}

.clear-button,
.clear-button:active,
.clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}
.search-bar-container {
  width: 100%;
  /* margin: 10px auto 0; */
}

.search-input-container {
  position: relative;
}
#rubbishPhoto, #footerPhoto, #rubbishAfterPhoto, #qrCodeGallery, #qrCodeArt {position: absolute; opacity: 0;width:1px;height:1px;}
.photo-preview {width: 75%;}


.bin-art {
  max-width: 100%;
  max-height: 30vh;
  margin-bottom: 1rem;
  border-radius: 4px;
}
.footer {
  background: #fff;
}
.artist-info {margin-top: 30px; border-top: 1px solid #ccc;padding-top:30px;padding-bottom: 50px;min-height:100vh;}
.art-box {
  border-radius: 7px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  padding-top: 15px;

}
.art-box a, .trashClass a {
  color: var(--primary-bg-color);

}

.MuiListItem-button:hover, .MuiListItem-button:hover svg {background-color: #fff !important;color: var(--primary-bg-color);}
.MuiListItem-button:hover svg {color: var(--primary-bg-color);}

.MuiListItem-button.active, .MuiListItem-button.active svg {
    background-color: var(--primary-bg-color) !important;
    color: #fff;
}
.MuiDataGrid-root{background-color: #fff;}
#submitForm {overflow-wrap: break-word;padding-bottom: 100px;}
.MuiDataGrid-row .MuiDataGrid-cell:first-child{border-right: 1px solid var(--secondary-bg-color);}
#root, body, html {min-height: 100%;height: 100%;}
#root > .admin {background: url('./assets/img/background.jpg');background-size: cover;min-height: 100%;height: 100%;overflow: scroll;}

.reporter-view h4 {font-size: 1.5rem; weight: 500; font-family: 'SF UI Text Medium';}
.reporter-view h5{
  font-family: 'SF UI Text Light';
font-style: normal;
font-weight: 0;
font-size: 14px;
line-height: 20px;
}
.report-another-issue {margin-top: 15px;}
.reporter-view h5 strong {
  font-family: 'SF UI Text Medium';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}
.reporter-view p {
font-family: 'SF UI Text Medium';
font-style: normal;
font-weight: 0;
font-size: 14px;
line-height: 20px;
}
.qty-indicator {
  position: absolute;
  font-size: 8px;
  top: 2px;
  left: 5px;
}
.qty-count
{
  font-family: 'SF UI Text Bold'
}
.reporter-view .MuiButton-root, .reporter-view .MuiOutlinedInput-root {border-radius: 7px;}
.reporter-view .art-divider {margin-top: 15px; margin-bottom: 15px;}
.success-check {max-width: 60%;}
.MuiMenu-paper {
    transition-duration: 0s !important;
}
:not(.MuiDataGrid-columnHeader--sorted) > div > div > .MuiDataGrid-iconButtonContainer {display: none;}
p.bin-identifier {
  margin-bottom: 0 !important;
}
h4.mt-5:first-of-type{margin-top: 45px !important;}
h5.text-secondary:first-of-type{margin-bottom: 10px !important;}
.art-gallery-slides{margin-bottom: 10px;}
p {margin-bottom:10px;}

.is-invalid {
  border-color: red;
}
